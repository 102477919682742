"use strict";
// Migrate database if you change the enums
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOOK_ONLY_LESSON_TYPES = exports.LESSON_TYPES_PER_CATEGORY = exports.LESSON_ALLOWED_EXERCISES = exports.LESSON_BOOKS = exports.LESSON_VARIANTS = exports.ELessonLevel = exports.ELessonBook = exports.ELessonType = exports.ELessonCategory = void 0;
var lesson_document_1 = require("./lesson-document");
var ELessonCategory;
(function (ELessonCategory) {
    ELessonCategory["DECODING"] = "decoding";
    ELessonCategory["READING_COMPREHENSION"] = "reading-comprehension";
    ELessonCategory["VOCABULARY"] = "vocabulary";
    ELessonCategory["BOOKSHELF"] = "bookshelf";
    ELessonCategory["STRONG_READER"] = "strong-reader";
})(ELessonCategory = exports.ELessonCategory || (exports.ELessonCategory = {}));
var ELessonType;
(function (ELessonType) {
    ELessonType["WORD_TOWER"] = "word-tower";
    ELessonType["REPETITION"] = "repetition";
    ELessonType["READING_COMPREHENSION"] = "reading-comprehension";
    ELessonType["TEST"] = "test";
    ELessonType["LIBRARY"] = "library";
    ELessonType["WORD_ROWS"] = "word-rows";
    ELessonType["VIDEO"] = "video";
})(ELessonType = exports.ELessonType || (exports.ELessonType = {}));
var ELessonBook;
(function (ELessonBook) {
    ELessonBook["DECODING_ONE"] = "decoding-one";
    ELessonBook["DECODING_TWO"] = "decoding-two";
    ELessonBook["DECODING_THREE"] = "decoding-three";
    ELessonBook["EXTRA_ONE"] = "extra-one";
    ELessonBook["READING_COMPREHENSION_ONE"] = "reading-comprehension-one";
    ELessonBook["READING_COMPREHENSION_TWO"] = "reading-comprehension-two";
})(ELessonBook = exports.ELessonBook || (exports.ELessonBook = {}));
var ELessonLevel;
(function (ELessonLevel) {
    ELessonLevel["LEVEL_3"] = "level-3";
    ELessonLevel["LEVEL_4"] = "level-4";
    ELessonLevel["LEVEL_5"] = "level-5";
    ELessonLevel["LEVEL_6"] = "level-6";
    ELessonLevel["LEVEL_7"] = "level-7";
    ELessonLevel["LEVEL_8"] = "level-8";
})(ELessonLevel = exports.ELessonLevel || (exports.ELessonLevel = {}));
exports.LESSON_VARIANTS = [
    { level: ELessonLevel.LEVEL_3, category: ELessonCategory.DECODING },
    { level: ELessonLevel.LEVEL_3, category: ELessonCategory.VOCABULARY },
    { level: ELessonLevel.LEVEL_3, category: ELessonCategory.STRONG_READER },
    { level: ELessonLevel.LEVEL_4, category: ELessonCategory.DECODING },
    { level: ELessonLevel.LEVEL_4, category: ELessonCategory.READING_COMPREHENSION },
    { level: ELessonLevel.LEVEL_4, category: ELessonCategory.STRONG_READER },
    { level: ELessonLevel.LEVEL_5, category: ELessonCategory.DECODING },
    { level: ELessonLevel.LEVEL_5, category: ELessonCategory.READING_COMPREHENSION },
    { level: ELessonLevel.LEVEL_5, category: ELessonCategory.STRONG_READER },
    { level: ELessonLevel.LEVEL_5, category: ELessonCategory.BOOKSHELF },
    { level: ELessonLevel.LEVEL_6, category: ELessonCategory.DECODING },
    { level: ELessonLevel.LEVEL_6, category: ELessonCategory.READING_COMPREHENSION },
    { level: ELessonLevel.LEVEL_6, category: ELessonCategory.STRONG_READER },
    { level: ELessonLevel.LEVEL_6, category: ELessonCategory.BOOKSHELF },
    { level: ELessonLevel.LEVEL_7, category: ELessonCategory.DECODING },
    { level: ELessonLevel.LEVEL_7, category: ELessonCategory.READING_COMPREHENSION },
    { level: ELessonLevel.LEVEL_7, category: ELessonCategory.STRONG_READER },
    { level: ELessonLevel.LEVEL_7, category: ELessonCategory.BOOKSHELF },
    { level: ELessonLevel.LEVEL_8, category: ELessonCategory.DECODING },
    { level: ELessonLevel.LEVEL_8, category: ELessonCategory.READING_COMPREHENSION },
    { level: ELessonLevel.LEVEL_8, category: ELessonCategory.STRONG_READER },
    { level: ELessonLevel.LEVEL_8, category: ELessonCategory.BOOKSHELF },
];
Object.freeze(exports.LESSON_VARIANTS);
exports.LESSON_BOOKS = [
    { category: ELessonCategory.DECODING, level: ELessonLevel.LEVEL_3, books: [ELessonBook.DECODING_ONE, ELessonBook.DECODING_TWO, ELessonBook.DECODING_THREE, ELessonBook.EXTRA_ONE] },
    { category: ELessonCategory.DECODING, level: ELessonLevel.LEVEL_4, books: [ELessonBook.DECODING_ONE, ELessonBook.DECODING_TWO] },
    { category: ELessonCategory.READING_COMPREHENSION, level: ELessonLevel.LEVEL_4, books: [ELessonBook.READING_COMPREHENSION_ONE, ELessonBook.READING_COMPREHENSION_TWO] },
    { category: ELessonCategory.DECODING, level: ELessonLevel.LEVEL_5, books: [ELessonBook.DECODING_ONE] },
    { category: ELessonCategory.READING_COMPREHENSION, level: ELessonLevel.LEVEL_5, books: [ELessonBook.READING_COMPREHENSION_ONE] },
    { category: ELessonCategory.DECODING, level: ELessonLevel.LEVEL_6, books: [ELessonBook.DECODING_ONE] },
    { category: ELessonCategory.READING_COMPREHENSION, level: ELessonLevel.LEVEL_6, books: [ELessonBook.READING_COMPREHENSION_ONE, ELessonBook.READING_COMPREHENSION_TWO] },
    { category: ELessonCategory.DECODING, level: ELessonLevel.LEVEL_7, books: [ELessonBook.DECODING_ONE] },
    { category: ELessonCategory.READING_COMPREHENSION, level: ELessonLevel.LEVEL_7, books: [ELessonBook.READING_COMPREHENSION_ONE] },
    { category: ELessonCategory.DECODING, level: ELessonLevel.LEVEL_8, books: [ELessonBook.DECODING_ONE] },
    { category: ELessonCategory.READING_COMPREHENSION, level: ELessonLevel.LEVEL_8, books: [ELessonBook.READING_COMPREHENSION_ONE] },
];
Object.freeze(exports.LESSON_BOOKS);
exports.LESSON_ALLOWED_EXERCISES = new Map([
    [ELessonCategory.DECODING, new Map([
            [ELessonType.WORD_TOWER, [lesson_document_1.EExerciseType.WORD_TOWER, lesson_document_1.EExerciseType.WORD_BLOCK, lesson_document_1.EExerciseType.READING_COMPREHENSION_TEXT]],
            [ELessonType.REPETITION, []],
            [ELessonType.READING_COMPREHENSION, [lesson_document_1.EExerciseType.READING_COMPREHENSION_TRANSCRIPT]],
            [ELessonType.TEST, []],
            [ELessonType.LIBRARY, []],
            [ELessonType.WORD_ROWS, []],
        ])],
    [ELessonCategory.READING_COMPREHENSION, new Map([
            [ELessonType.READING_COMPREHENSION, [lesson_document_1.EExerciseType.READING_COMPREHENSION_TRANSCRIPT, lesson_document_1.EExerciseType.READING_COMPREHENSION_TEXT, lesson_document_1.EExerciseType.VIDEO]],
            [ELessonType.REPETITION, []],
            [ELessonType.TEST, []],
        ])],
    [ELessonCategory.VOCABULARY, new Map([ // Type added here to satisfy typescript since it assume unknown if it's an empty map
        // ['null', [EExerciseType.HIDDEN_OBJECTS_GAME]], // Only one HIDDEN_OBJECT_GAMEs is allowed and that one will be provided on create
        ])],
    [ELessonCategory.STRONG_READER, new Map([
            [ELessonType.VIDEO, [lesson_document_1.EExerciseType.VIDEO]],
        ])],
]);
exports.LESSON_TYPES_PER_CATEGORY = new Map();
exports.LESSON_ALLOWED_EXERCISES.forEach(function (value, category) {
    var lessonTypes = Array.from(value.keys()).filter(function (type) { return type !== 'null'; });
    exports.LESSON_TYPES_PER_CATEGORY.set(category, lessonTypes);
});
exports.BOOK_ONLY_LESSON_TYPES = [
    null,
    undefined,
    ELessonType.LIBRARY,
    ELessonType.TEST,
    ELessonType.REPETITION,
    ELessonType.WORD_ROWS,
];
Object.freeze(exports.BOOK_ONLY_LESSON_TYPES);
